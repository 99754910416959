.container {
    max-width: 800px;
}

.centered-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align content to the left */
    min-height: 100vh;
    justify-content: center; /* Center content horizontally */
}

body {
    background-color: #242424;
    color: #f5f5f5;
    font-family: "muli", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.name-header {
    font-family: "input-sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.7rem;
    color: #f5f5f5; /* Light Off-White Text */
    margin: 0; /* Remove default margin for <h1> elements */
  }

.title-header {
    font-family: "eurostile-extended", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2rem;
    color: #f5f5f5; /* Light Off-White Text */
}

  :root {
    --gradient: linear-gradient(45deg, #c31432, #240b36);
  
  }

  .rainbow-line {
    width: 100%;
    height: 6px;
    background: #ddd;
    background-image: var(--gradient);
    border: none;
    margin: 10px 0;
    background-position: 0% 0;
    background-size: 100% 100%; /* Set background-size to cover the entire element */
    opacity: 1;
    transition: background-position 25s linear;
}


.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px; /* Adjust the padding to control the spacing */
    margin-bottom: 8px;
  }
  
  .nav-link {
    margin: 0 10px; /* Adjust the margin to control the space between items */
    position: relative; /* Create a relative positioning context for the pseudo-element */
    font-size: 1.5rem; /* Adjust the font size to make the letters bigger */
  }

.nav-link::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px; /* Adjust the height of the underline as needed */
    bottom: 0; /* Position the underline at the bottom of the link */
    background-image: var(--gradient);
    background-size: 200% 100%; /* Adjust the background size for the gradient */
    background-position: 100% 0; /* Start with the gradient at the right */
    transition: background-position 0.3s; /* Add a transition for animation */
}

.nav-link:hover::before {
    background-position: 0 0; /* Animate the gradient to the left on hover */
}

.about-me {
    font-size: 1.2rem;
}

.content {
    position: relative;
    padding-left: 20px;
}

/* Create a left-border with a gradient */
.content::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px; /* Adjust the width as needed */
    background-image: var(--gradient);
    border-radius: 0px 0 0 0px;
  }

.different-color {
    background: var(--gradient); /* Use the same gradient as the rest of the content */
    -webkit-background-clip: text; /* Clip the gradient to the text */
    color: transparent; /* Make the text transparent */
}

.projects-container {
  padding-left: 10px !important;
}

.project-list {
  display: flex;
  flex-direction: row; /* Set the direction to column for a vertical layout */
  
}

.project-buttons {
  border: none;
  display: flex;
  flex-direction: column; /* Set the direction to column for a vertical layout */
  align-items: flex-start; /* Align content to the left within the column */
  color: #f5f5f5; /* Light Off-White Text */
  background-color: #242424;
  margin-right: 20px;
  width: 200px;
}

.project-button {
  font-size: 1rem;
  padding-bottom: 3px;
  margin-bottom: 30px;
}

.card {
  display: flex;
  border: none;
}

.card-body {
  padding-top: 0px;
  background-color: #242424;
  color:#f5f5f5;
  padding-left: 22px;
}

.details {
  display: flex;
  flex-direction: column;
}

button.btn.btn-primary {
  font-size: 1.2rem; /* Adjust the font size as needed */
  background-color: #242424;
  align-self: flex-end;
  margin-right: 20px;
  border: 2px solid transparent; /* Set a transparent border */
  padding: 10px 20px; /* Adjust the padding as needed */
}

button.btn.btn-primary:hover {
  background-image: var(--gradient); /* Apply the gradient background on hover */
  background-size: 200% 100%; /* Adjust the background size for the gradient */
  background-position: 100% 0; /* Start with the gradient at the right */
  transition: background-position 0.3s; /* Add a transition for animation */
}

button.btn.btn-primary:active {
  border: 2px solid transparent; /* Set a transparent border *//* Remove the blue outline on click */
}


.form {
  font-size: 16px;
}

.form-container {
  width: 100%;
}

/**/
.form * {
  box-sizing: border-box;
  line-height: 1.5;
}

.form_title {
  font-size: 2em;
  font-weight: 600;
}

.form_item {
  display: flex;
  flex-direction: row; /* Display the children in a row (horizontal alignment) */
  align-items: center; /* Vertically align items in the center of each row */
  margin-bottom: 10px; /* Add margin to separate rows */
}

.side-by-side {
  display: flex;
  flex-direction: row; /* Display the children in a row (horizontal alignment) */
  align-items:center; /* Vertically align items in the center of each row */
  margin-bottom: 10px; /* Add margin to separate rows */
}

.form_item > * {
  align-self: flex-start;
}

.area-text {
  width: 96%;
  color: #f5f5f5;

  padding: 0.5em;
  font-size: 0.9;

  outline: none;
  border: 1px solid #dabdcb; /* Specify the border color with opacity */
  border-radius: 4px;
  background: #242424;
  transition: background 0.25s, border-color 0.25s, color 0.25s;
}

.form_input {
  width: 100%;
  max-width: 400px;
  color: #f5f5f5;

  padding: 0.5em;
  font-size: 0.9;

  outline: none;
  border: 1px solid #dabdcb; /* Specify the border color with opacity */
  border-radius: 4px;
  background: #242424;
  transition: background 0.25s, border-color 0.25s, color 0.25s;
}

.form_input:focus {
  background: #424141;
}

.form_input::placeholder {
  color: #f5f5f575;
}

.form_input--small {
  max-width: 250px;
}

.form_btn {
  margin-top: 15px;
  margin-right: 10px;
  font-weight: 600;
  font-size:1.1em;
  padding: 10px 16px;
  color: #ffffff;
  background-color: #242424;
  border: none;
  border-radius: 5px;
}

.form_btn:hover {
  background-image: var(--gradient); /* Apply the gradient background on hover */
  background-size: 200% 100%; /* Adjust the background size for the gradient */
  background-position: 100% 0; /* Start with the gradient at the right */
  transition: background-position 0.3s; /* Add a transition for animation */
}

.individual-item {
  margin-right: 20px;
}

.navbar-toggler {
  background-image: var(--gradient);
  margin-bottom: 7px;
}

@media screen and (max-width: 795px) {
  .card {
    width : 400px !important; 
  }

  .card-body{
    padding-left: 0px;
  }
}

@media screen and (max-width: 625px) {
  .card {
    width : 350px !important; 
  }

  .card-body{
    padding-left: 0px;
  }

  .project-buttons {
    margin-right: 2px;
    width: 150px;
  }
}

@media screen and (max-width: 490px) {
  .card {
    width : 300px !important; 
  }
}

@media screen and (max-width: 455px) {
  .card {
    width : 250px !important; 
  }
}

@media screen and (max-width: 410px) {
  .card {
    width : 225px !important; 
  }
  .project-buttons {
    width: 100px;
  }
}

@media screen and (max-width: 345px) {
  .card {
    width : 200px !important; 
  }
}